import React from "react";
import { axiosClient } from "../../core/config";
import { Modal } from "antd";
import WindowSuccess from "../ModalWindows/WindowSuccess";
import WindowError from "../ModalWindows/WindowError";
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import './formStyle.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

type TMyform = {
    name: string;
    email: string;
    phone: string;
    textMessage: string;
}

const FormChrysanthemum: React.FC<{ openModal: boolean, closeWindow: any }> = ({ openModal, closeWindow }) => {
    const [errorSubmit, setErrorSubmit] = React.useState(false);
    const [successSubmit, setSuccessSubmit] = React.useState(false); 
    const closeWindows = () => {
        setErrorSubmit(false)
        setSuccessSubmit(false)
    }

    const { register, handleSubmit, formState: { errors } } = useForm<TMyform>({
        defaultValues: {
            name: '',
            email: '',
            phone: '', 
            textMessage: ''
        }
    });
   
    const submit: SubmitHandler<TMyform> = async (data, event) => {
        try {
            const response = await axiosClient.post('wp-json/yakov/v1/mailChrysanthemum', data);
            console.log('Отправилось успешно', response.data);
            setSuccessSubmit(true)
            event?.target.reset();
        } catch (error) {
            setErrorSubmit(true);
            console.error('Ошибка при отправке', error);

        }
    };
    const error: SubmitErrorHandler<TMyform> = (error) => {
        console.log('ошибка при отправлени', error)

    }


    return (
        <Modal className="windowModal" open={openModal} closeIcon={<FontAwesomeIcon icon={faXmark} />} onCancel={closeWindow} footer={null}>
           <WindowError errorSubmit={errorSubmit} closeWindow={closeWindows} />
        <WindowSuccess successSubmit={successSubmit} closeWindow={closeWindows} />
            <div className="course-form">

                <form onSubmit={handleSubmit(submit, error)}>
                    <div className="course-form__title">
                        Оформление заказа курса "Хризантема мультифлора выращивание и бизнес-секреты"
                    </div>

                    <div className="price">Цена: 790 ₽</div>



                    <label className={errors.name ? 'error' : ''}> 
                                <span>Имя</span>
                                <input {...register('name', { 
                                    required: true,
                                    pattern: /^[A-Za-zА-Яа-яЁё\s]+$/i,
                                })} placeholder='Введите ваше имя...' type="text" />
                                {errors.name && errors.name.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.name && errors.name.type === 'pattern' && (
                                    <p>Имя может содержать только буквы</p>
                                )}
                            </label>

                            <label className={errors.email ? 'error' : ''}>
                                <span>E-mail</span>
                                <input {...register('email', {
                                    required: true,
                                    pattern:  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                })} placeholder='Введите ваш E-mail...' type="text" />
                                {errors.email && errors.email.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.email && errors.email.type === 'pattern' && (
                                    <p>Введите корректный email</p> 
                                )}
                            </label>
                    <label className={errors.phone ? 'error' : ''}>
                        <span>Телефон</span>
                        <input {...register('phone', {
                            required: true,
                            pattern: /^[+]?[0-9]+$/,
                            minLength: 11,
                        })} placeholder='Введите ваш телефон...' type="text" />
                        {errors.phone && errors.phone.type === 'required' && (
                            <p>Поле обязательно для заполнения</p>
                        )}
                        {errors.phone && errors.phone.type === 'pattern' && (
                            <p>Телефон может содержать только цифры</p>
                        )}
                        {errors.phone && errors.phone.type === 'minLength' && (
                            <p>Телефона должен содержать минимум 11 символов</p>
                        )}
                    </label>

                    <label>
                        <span>Комментарий или вопрос </span>
                        <textarea placeholder='Напишите ваш вопрос или комментарий...' {...register('textMessage')}>

                        </textarea>
                    </label>
                    <button type='submit' className='btn-form'>
                        Заказать
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default FormChrysanthemum;