import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export type filterActive = {
    name:string, 
    sortProperty: string 
}

 interface FilterSliceState {
    filterMobileOpen: boolean,
    filterActive: filterActive,
    searchValue: string,
    currentPage: number,
    categoryId: string
}

const initialState:FilterSliceState = {
    filterMobileOpen: false,
    filterActive: {
        name: 'По умолчанию', 
        sortProperty: 'default'
    },
    searchValue: '',
    currentPage: 1,
    categoryId: ''
}

const filterSlice = createSlice({
    name: 'filterSlice',
    initialState,
    reducers: {
        setFilters(state, action: PayloadAction<filterActive>) {
            state.filterActive = action.payload
        },
        setSearch(state, action: PayloadAction<string>) {
            state.searchValue = action.payload
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.currentPage = action.payload
        },
        setLocation(state, action) { 
            state.currentPage = Number(action.payload.currentPage);
            state.filterActive = action.payload.sort;
            state.categoryId = action.payload.categoryId;
        },
        setCategoryId(state, action) {
            state.categoryId = action.payload;
        },
        setFilterMobileOpen(state, action) {
            state.filterMobileOpen = action.payload
        }
    }
})


export const {setFilters, setSearch, setCurrentPage, setLocation, setCategoryId, setFilterMobileOpen} = filterSlice.actions;

export default filterSlice.reducer