import React from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { filterActive, setFilters } from "../../redux/slices/filterSlice";
import { RootState } from "../../redux/store";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';



type TSort = {
    value: filterActive,
  
}



export const filterList = [
    { name: 'По умолчанию', sortProperty: 'default' },
    { name: 'В наличии', sortProperty: 'stock_status=instock' },
    { name: 'По популярности', sortProperty: 'orderby=popularity&order=desc' },
    { name: 'Сначала дороже', sortProperty: 'orderby=price&order=desc' },
    { name: 'Сначала дешевле', sortProperty: 'orderby=price&order=asc' },
]

const Sort: React.FC<TSort> = React.memo(({value}) => { 
    const dispatch = useDispatch();
    const {filterActive} = useSelector((state: RootState) => state.filter)
    const isSortMobile = useMediaQuery('(max-width: 1200px)');
    const [sortMobileOpen, setSortMobileOpen] = React.useState(false);
    const toggleSortMobile = () => {
        setSortMobileOpen(!sortMobileOpen)
    }
    const sortRef = React.useRef<HTMLDivElement>(null);


   
    const onClickFilter = React.useCallback((index: {
        name: string,
        sortProperty: string
    }) => {
        
        dispatch(setFilters(index)) 
        
    }, []) 

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
                setSortMobileOpen(false);
            }
        };
    
        document.body.addEventListener('click', handleClickOutside);
    
        return () => document.body.removeEventListener('click', handleClickOutside);
    }, []);
    
    return (
        isSortMobile ?   
            <div ref={sortRef} className={`line-sort sort-mobile ${sortMobileOpen ? 'show' : ''}`} onClick={toggleSortMobile}> 
            <span className="sort-title">Сортировка:</span>
            <div className="sort-active">{filterActive.name}<span className="sort-active__icon"><FontAwesomeIcon icon={faAngleDown} /></span></div>
            <ul className="sort-box">

                {filterList.map((obj, i) => (
                    <li key={i} onClick={() => onClickFilter(obj)} className={`sort-item ${value.sortProperty === obj.sortProperty ? 'active' : ''}`}>{obj.name}</li>
                ))}
            </ul>
        </div>

        : 

        <div className="line-sort">
        <span>Сортировка:</span>
        <ul className="sort-box">

            {filterList.map((obj, i) => (
                <li key={i} onClick={() => onClickFilter(obj)} className={`sort-item ${value.sortProperty === obj.sortProperty ? 'active' : ''}`}>{obj.name}</li>
            ))}
        </ul>
    </div>
        
       
    );
})

export default Sort;